import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import PageNotFound from "../molecules/PageNotFound/404_astro";

const NotFoundPage = () => (
  <Layout hideMap hideFAQ>
    <SEO title="404: Page Not found" />
    <div className="section m-top-20">
      <div className="container">
        <PageNotFound></PageNotFound>
      </div>
    </div>
  </Layout>
);

export default NotFoundPage;
